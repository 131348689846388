import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Nav = ({ type, slug, divisionName }) => {
  let divisionLink;
  if (slug === "mb" || slug === "ab" || slug === "on") {
    divisionLink = "https://www.rcmp-grc.gc.ca/" + slug + "/index-eng.htm";
  } else if (slug === "bc") {
    divisionLink = "https://bc-cb.rcmp-grc.gc.ca/ViewPage.action?siteNodeId=14&languageId=1";
  } else if (slug === "ca") {
    divisionLink = "https://www.rcmp-grc.gc.ca/en";
  } else if (slug === "ncr-rcn") {
    divisionLink = "https://www.rcmp-grc.gc.ca/en/ottawa/home";
  } else if (slug === "yt") {
    divisionLink = "https://www.rcmp-grc.gc.ca/en/yk/home";
  } else {
    divisionLink = "https://www.rcmp-grc.gc.ca/en/" + slug + "/home";
  }

  let divisionPronouns;
  if (slug === "nt") {
    divisionPronouns = "the";
  } else {
    divisionPronouns = "";
  }

  switch (type) {
    case "division":
      return (
        <nav id="wb-bc" property="breadcrumb">
          <h2>You are here:</h2>
          <div class="container">
            <ol class="breadcrumb">
              <li>
                <a href="https://www.rcmp-grc.gc.ca/en">
                  <abbr>RCMP</abbr>.ca
                </a>
              </li>
              {(() => {
                if (slug !== "ca") {
                  return (
                    <li>
                      <a href={divisionLink}>{divisionName}</a>
                    </li>
                  );
                }
              })()}
            </ol>
          </div>
        </nav>
      );
    case "alert":
      return (
        <nav id="wb-bc" property="breadcrumb">
          <h2>You are here:</h2>
          <div class="container">
            <ol class="breadcrumb">
              <li>
                <a href="https://www.rcmp-grc.gc.ca/en">
                  <abbr>RCMP</abbr>.ca
                </a>
              </li>
              {(() => {
                if (slug !== "ca") {
                  return (
                    <li>
                      <a href={divisionLink}>{divisionName}</a>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (slug === "ca") {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Emergency alerts issued by the <abbr>RCMP</abbr>
                      </Link>
                    </li>
                  );
                } else if (slug === "ncr-rcn") {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Emergency alerts issued in the National Capital Region
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Emergency alerts issued by {divisionPronouns} {divisionName}
                      </Link>
                    </li>
                  );
                }
              })()}
            </ol>
          </div>
        </nav>
      );
    default:
      return null;
  }
};

Nav.propTypes = {
  type: PropTypes.string,
};

Nav.defaultProps = {
  type: ``,
};

export default Nav;
