import * as React from "react";
import PropTypes from "prop-types";
import footerImg from "../../../static/GCWeb/assets/wmms-blk.svg";
// import Link from "gatsby";

const Footer = ({ siteTitle }) => {
  return (
    <footer id="wb-info">
      <div class="brand">
        <div class="container">
          <div class="row">
            <nav class="col-md-9 col-lg-10 ftr-urlt-lnk">
              <h2 class="wb-inv">About this site</h2>
              <ul>
                <li>
                  <a href="https://www.rcmp-grc.gc.ca/en/terms-conditions">Terms and conditions</a>
                </li>
                <li>
                  <a href="https://www.rcmp-grc.gc.ca/en/transparency">Transparency</a>
                </li>
              </ul>
            </nav>
            <div class="col-xs-6 visible-sm visible-xs tofpg">
              <a href="#wb-cont">
                Top of page <span class="glyphicon glyphicon-chevron-up"></span>
              </a>
            </div>
            <div class="col-xs-6 col-md-3 col-lg-2 text-right">
              <img alt="Symbol of the Government of Canada" src={footerImg}></img>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
