/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, withPrefix } from "gatsby";
import Seo from "../seo";
import Helmet from "react-helmet";
import Skip from "../skip.js";
import Header from "../header/header.en";
import Footer from "../footer/footer.en";
import "../../../static/GCWeb/assets/favicon.ico";
import "../../../static/GCWeb/assets/wmms-blk.svg";
// import DevFooter from "../footer/dev-footer" */
// import "../../static/GCWeb/css/theme.min.css"
// import "../../static/wet-boew/css/noscript.min.css"

const Layout = ({ siteTitle, children, slug, divisionName, languageToggle, nav }) => {
  useStaticQuery(graphql`
    query SiteTitleQuery3 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Seo title={siteTitle} />
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
      </Helmet>
      <Skip />
      <Header siteTitle={siteTitle} slug={slug} divisionName={divisionName} languageToggle={languageToggle} nav={nav} />
      {children}
      <Footer />
      <Helmet>
        <link href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" rel="stylesheet" type="text/css" />
        <link href={withPrefix("/GCWeb/css/theme.min.css")} rel="stylesheet" type="text/css" />
      </Helmet>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.node.isRequired,
  languageToggle: PropTypes.node.isRequired,
};

export default Layout;
