import * as React from "react";
import Layout from "../components/layout/layout.en";
import Seo from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Error 404 - RCMP emergency alert | Erreur 404 - Alerte d'urgence de la GRC" />
      <div className="container">
        <h1 class="wb-inv" id="wb-cont">
          We couldn't find that Web page <span lang="fr">Nous ne pouvons trouver cette page Web</span>
        </h1>
        <div class="row mrgn-tp-lg mrgn-bttm-lg">
          <div class="col-md-12">
            <div class="row">
              <section class="col-md-6">
                <div class="row">
                  <div class="col-xs-3 col-sm-2 col-md-2 text-center mrgn-tp-md">
                    {" "}
                    <span class="glyphicon glyphicon-warning-sign glyphicon-error"></span>{" "}
                  </div>
                  <div class="col-xs-9 col-sm-10 col-md-10">
                    <h2 class="mrgn-tp-md">We couldn't find that Web page</h2>
                    <p class="pagetag">
                      <b>Error 404</b>
                    </p>
                  </div>
                </div>
                <p>We're sorry you ended up here. Sometimes a page gets moved or deleted, but hopefully we can help you find what you're looking for. What next?</p>
                <ul>
                  <li>
                    Return to the <Link href="/home">home page</Link>;
                  </li>
                </ul>
              </section>
              <section class="col-md-6" lang="fr">
                <div class="row">
                  <div class="col-xs-3 col-sm-2 col-md-2 text-center mrgn-tp-md">
                    {" "}
                    <span class="glyphicon glyphicon-warning-sign glyphicon-error"></span>{" "}
                  </div>
                  <div class="col-xs-9 col-sm-10 col-md-10">
                    <h2 class="mrgn-tp-md">Nous ne pouvons trouver cette page Web</h2>
                    <p class="pagetag">
                      <b>Erreur 404</b>
                    </p>
                  </div>
                </div>
                <p>Nous sommes désolés que vous ayez abouti ici. Il arrive parfois qu’une page ait été déplacée ou supprimée. Heureusement, nous pouvons vous aider à trouver ce que vous cherchez. Que faire?</p>
                <ul>
                  <li>
                    Retournez à la <Link href="/acceuil">page d’accueil</Link>;
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
